import { AxiosError } from "axios";
import { ErrorResponse } from "./interfaces";

export const latLngRegEx =
  /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

export function genericServerErrorHandler(
  error: AxiosError,
  callback: (str: string) => void
) {
  const response = error.response as ErrorResponse;
  console.log(error);
  switch (error.response?.status) {
    case 401:
      callback(response.data.message || "Не сте логнат");
      break;
    case 403:
      callback(response.data.message || "Нямате достатъчни права");
      break;
    case 422:
      callback(response.data.message || "Липсващи данни");
      break;
    default:
      callback(error.message);
      break;
  }
}
