import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Stack } from "@mui/material";
import { useContext, useMemo } from "react";
import { RouteSettingsContext } from "../../../context/RouteSettingsContext";
import RouteStopRow from "../RouteStopRow";

export default function RouteStops() {
  const { currentRouteCache, switchStopsOrder } =
    useContext(RouteSettingsContext);
  function handleDragEnd(event: DragEndEvent) {
    const activeStop = currentRouteCache.stops.find(
      (stop) => stop.id === event.active.id
    );
    const overStop = currentRouteCache.stops.find(
      (stop) => stop.id === event.over?.id
    );
    if (activeStop && overStop) {
      switchStopsOrder(
        currentRouteCache.stops.indexOf(activeStop),
        currentRouteCache.stops.indexOf(overStop)
      );
    }
  }
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor)
  );
  const itemIds = useMemo(
    () => currentRouteCache.stops.map((stop) => stop.id),
    [currentRouteCache.stops]
  ); // ["1", "2", "3"]

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <Stack
        spacing={1.25}
        sx={{ overflow: "auto", maxHeight: "calc(100vh - 260px)" }}
      >
        <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
          {currentRouteCache.stops.map((stop) => (
            <RouteStopRow key={stop.id} stop={stop} />
          ))}
        </SortableContext>
      </Stack>
    </DndContext>
  );
}
