import { Typography, AppBar, Toolbar } from "@mui/material";
import HeaderTabs from "./HeaderTabs";

export default function Header() {
  return (
    <AppBar
      sx={{
        paddingX: 2,
        backgroundColor: "#fff",
      }}
    >
      <Toolbar disableGutters variant="dense">
        <Typography
          variant="subtitle2"
          component="div"
          color="#666666"
          sx={{ textTransform: "uppercase", flexGrow: 1 }}
        >
          transport.poslushen.com
        </Typography>
        <HeaderTabs />
      </Toolbar>
    </AppBar>
  );
}
