import { arrayMove } from "@dnd-kit/sortable";
import { createContext, useReducer } from "react";
import { Route, RouteStop } from "../interfaces";

enum RouteActionKind {
  ADD_ROUTE = "ADD_ROUTE",
  ADD_STOP = "ADD_STOP",
  REMOVE_CURRENT = "REMOVE_CURRENT",
  REMOVE_STOP = "REMOVE_STOP",
  EDIT_STOP_TITLE = "EDIT_STOP_TITLE",
  EDIT_STOP_COORDS = "EDIT_STOP_COORDS",
  SAVE_CURRENT = "SAVE_CURRENT",
  EDIT_TITLE = "EDIT_TITLE",
  START_EDIT = "START_EDIT",
  END_EDIT = "END_EDIT",
  SET_ACTIVE = "SET_ACTIVE",
  SET_ROUTES = "SET_ROUTES",
  SWITCH_STOPS_ORDER = "SWITCH_STOPS_ORDER",
}

interface AddRouteAction {
  type: RouteActionKind.ADD_ROUTE;
  payload: Route;
}

interface AddStopAction {
  type: RouteActionKind.ADD_STOP;
  payload: RouteStop;
}

interface SetActiveAction {
  type: RouteActionKind.SET_ACTIVE;
  payload: Route;
}

interface SetRoutesAction {
  type: RouteActionKind.SET_ROUTES;
  payload: Route[];
}

interface StartEditAction {
  type: RouteActionKind.START_EDIT;
}

interface EndEditAction {
  type: RouteActionKind.END_EDIT;
}

interface SwitchStopsOrderAction {
  type: RouteActionKind.SWITCH_STOPS_ORDER;
  payload: {
    activeIndex: number;
    overIndex: number;
  };
}

interface RemoveActiveRouteAction {
  type: RouteActionKind.REMOVE_CURRENT;
}

interface RemoveStopAction {
  type: RouteActionKind.REMOVE_STOP;
  payload: RouteStop;
}

interface SaveCurrentAction {
  type: RouteActionKind.SAVE_CURRENT;
}

interface EditStopTitleAction {
  type: RouteActionKind.EDIT_STOP_TITLE;
  payload: {
    newTitle: string;
    stopId: number;
  };
}

interface EditStopCoordsAction {
  type: RouteActionKind.EDIT_STOP_COORDS;
  payload: {
    latLng: google.maps.LatLngLiteral;
    stopId: number;
  };
}

interface EditTitleAction {
  type: RouteActionKind.EDIT_TITLE;
  payload: string;
}

interface RoutesState {
  isEdit: boolean;
  routes: Route[];
  activeRouteIndex: number;
  currentRouteCache: Route;
}

type RouteAction =
  | RemoveActiveRouteAction
  | RemoveStopAction
  | AddRouteAction
  | AddStopAction
  | EditStopTitleAction
  | EditStopCoordsAction
  | SaveCurrentAction
  | EditTitleAction
  | SetActiveAction
  | SetRoutesAction
  | StartEditAction
  | EndEditAction
  | SwitchStopsOrderAction;

interface RouteSettingsContextType {
  isEdit: boolean;
  routes: Route[];
  currentRouteCache: Route;
  addRoute: () => void;
  addStop: () => void;
  saveCurrent: () => void;
  duplicateCurrent: () => void;
  editRouteTitle: (newTitle: string) => void;
  editStopCoordinates: (
    latLng: google.maps.LatLngLiteral,
    stopId: number
  ) => void;
  editStopTitle: (newTitle: string, stopId: number) => void;
  setActive: (route: Route) => void;
  setRoutes: (routes: Route[]) => void;
  startEdit: () => void;
  endEdit: () => void;
  removeActiveRoute: () => void;
  removeStop: (stop: RouteStop) => void;
  switchStopsOrder: (activeIndex: number, overIndex: number) => void;
  isCurrentRouteActive: (routeId: number) => boolean;
}

function createEmptyStop(newId: number, title: string = ""): RouteStop {
  return {
    id: newId,
    title,
    coordinates: { lat: 0, lng: 0 },
  };
}

function createNewRoute(
  newId: number,
  title: string,
  initialStopId: number,
  finalStopId: number
): Route {
  return {
    id: newId,
    title,
    isNew: true,
    active: false,
    stops: [
      createEmptyStop(initialStopId, "Начална спирка"),
      createEmptyStop(finalStopId, "Крайна спирка"),
    ],
  };
}

function routeReducer(state: RoutesState, action: RouteAction): RoutesState {
  switch (action.type) {
    case RouteActionKind.ADD_ROUTE:
      return {
        activeRouteIndex: action.payload.id,
        isEdit: true,
        routes: [...state.routes, action.payload],
        currentRouteCache: { ...action.payload },
      };
    case RouteActionKind.ADD_STOP:
      const stopsCopy = state.currentRouteCache.stops.slice();
      stopsCopy.splice(stopsCopy.length - 1, 0, action.payload);
      return {
        ...state,
        currentRouteCache: {
          ...state.currentRouteCache,
          stops: stopsCopy,
        },
      };
    case RouteActionKind.EDIT_TITLE:
      return {
        ...state,
        currentRouteCache: {
          ...state.currentRouteCache,
          title: action.payload,
        },
      };
    case RouteActionKind.SET_ACTIVE:
      return {
        ...state,
        isEdit: false,
        activeRouteIndex: action.payload.id,
        currentRouteCache: { ...action.payload },
      };
    case RouteActionKind.SET_ROUTES:
      return {
        ...state,
        isEdit: false,
        activeRouteIndex: action.payload[0] ? action.payload[0].id : 0,
        currentRouteCache: action.payload[0],
        routes: action.payload,
      };
    case RouteActionKind.SAVE_CURRENT:
      return {
        ...state,
        isEdit: false,
        routes: state.routes.map((route) => {
          if (route.id !== state.currentRouteCache.id) {
            return route;
          } else {
            return { ...state.currentRouteCache, isNew: false };
          }
        }),
      };
    case RouteActionKind.SWITCH_STOPS_ORDER:
      const currentRouteStopsCopy = state.currentRouteCache.stops.slice();
      return {
        ...state,
        currentRouteCache: {
          ...state.currentRouteCache,
          stops: arrayMove(
            currentRouteStopsCopy,
            action.payload.activeIndex,
            action.payload.overIndex
          ),
        },
      };
    case RouteActionKind.REMOVE_CURRENT:
      const filteredRoutes = state.routes.filter(
        (route) => route.id !== state.currentRouteCache.id
      );
      return {
        activeRouteIndex: filteredRoutes[0]?.id,
        isEdit: false,
        routes: filteredRoutes,
        currentRouteCache: filteredRoutes[0],
      };
    case RouteActionKind.REMOVE_STOP:
      return {
        ...state,
        currentRouteCache: {
          ...state.currentRouteCache,
          stops: [
            ...state.currentRouteCache.stops.filter(
              (stop) => stop.id !== action.payload.id
            ),
          ],
        },
      };
    case RouteActionKind.START_EDIT:
      return {
        ...state,
        isEdit: true,
      };
    case RouteActionKind.END_EDIT:
      // Check if current active route is new or existing
      const currentActiveRouteOriginal = state.routes.find(
        (route) => route.id === state.currentRouteCache.id
      );
      // Existing
      if (currentActiveRouteOriginal?.isNew === false) {
        return {
          ...state,
          isEdit: false,
          currentRouteCache: { ...currentActiveRouteOriginal },
        };
      }
      // New
      return {
        ...state,
        isEdit: false,
        activeRouteIndex: state.routes[0].id,
        routes: state.routes.filter(
          (route) => route.id !== state.currentRouteCache.id
        ),
        currentRouteCache: {
          ...state.routes[0],
        },
      };
    case RouteActionKind.EDIT_STOP_TITLE:
      return {
        ...state,
        currentRouteCache: {
          ...state.currentRouteCache,
          stops: state.currentRouteCache.stops.map((stop) => {
            if (stop.id !== action.payload.stopId) {
              return stop;
            } else {
              return { ...stop, title: action.payload.newTitle };
            }
          }),
        },
      };
    case RouteActionKind.EDIT_STOP_COORDS:
      return {
        ...state,
        currentRouteCache: {
          ...state.currentRouteCache,
          stops: state.currentRouteCache.stops.map((stop) => {
            if (stop.id !== action.payload.stopId) {
              return stop;
            } else {
              return { ...stop, coordinates: action.payload.latLng };
            }
          }),
        },
      };
    default:
      throw new Error();
  }
}

export const RouteSettingsContext = createContext<RouteSettingsContextType>(
  null!
);

let largestRouteId = 0;
let largestStopId = 1;

export function RouteSettingsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const initialRoutes: Route[] = [];

  const [routesState, dispatchRouteChange] = useReducer(routeReducer, {
    activeRouteIndex: 0,
    isEdit: false,
    routes: initialRoutes,
    currentRouteCache: initialRoutes[0],
  });

  const value = {
    isEdit: routesState.isEdit,
    routes: routesState.routes,
    currentRouteCache: routesState.currentRouteCache,
    addRoute() {
      dispatchRouteChange({
        type: RouteActionKind.ADD_ROUTE,
        payload: createNewRoute(
          largestRouteId++,
          "Маршрут " + largestRouteId,
          largestStopId++,
          largestStopId++
        ),
      });
    },
    addStop() {
      dispatchRouteChange({
        type: RouteActionKind.ADD_STOP,
        payload: createEmptyStop(
          largestStopId++,
          "Спирка " + routesState.currentRouteCache.stops.length
        ),
      });
    },
    saveCurrent() {
      dispatchRouteChange({
        type: RouteActionKind.SAVE_CURRENT,
      });
    },
    duplicateCurrent() {
      dispatchRouteChange({
        type: RouteActionKind.ADD_ROUTE,
        payload: {
          ...routesState.currentRouteCache,
          title: routesState.currentRouteCache.title + " " + largestRouteId,
          id: largestRouteId++,
          isNew: true,
        },
      });
    },
    editRouteTitle(newTitle: string) {
      dispatchRouteChange({
        payload: newTitle,
        type: RouteActionKind.EDIT_TITLE,
      });
    },
    setActive(route: Route) {
      dispatchRouteChange({
        type: RouteActionKind.SET_ACTIVE,
        payload: route,
      });
    },
    setRoutes(routes: Route[]) {
      largestRouteId = routes
        .map((route) => route.id)
        .reduce((id, current) => id + current, 0);
      largestRouteId++;
      dispatchRouteChange({
        type: RouteActionKind.SET_ROUTES,
        payload: routes,
      });
    },
    editStopTitle(newTitle: string, stopId: number) {
      dispatchRouteChange({
        type: RouteActionKind.EDIT_STOP_TITLE,
        payload: {
          newTitle,
          stopId,
        },
      });
    },
    editStopCoordinates(latLng: google.maps.LatLngLiteral, stopId: number) {
      dispatchRouteChange({
        type: RouteActionKind.EDIT_STOP_COORDS,
        payload: {
          latLng,
          stopId,
        },
      });
    },
    switchStopsOrder(activeIndex: number, overIndex: number) {
      dispatchRouteChange({
        type: RouteActionKind.SWITCH_STOPS_ORDER,
        payload: {
          activeIndex,
          overIndex,
        },
      });
    },
    removeActiveRoute() {
      dispatchRouteChange({
        type: RouteActionKind.REMOVE_CURRENT,
      });
    },
    removeStop(stopToRemove: RouteStop) {
      dispatchRouteChange({
        type: RouteActionKind.REMOVE_STOP,
        payload: stopToRemove,
      });
    },
    startEdit() {
      dispatchRouteChange({
        type: RouteActionKind.START_EDIT,
      });
    },
    endEdit() {
      dispatchRouteChange({
        type: RouteActionKind.END_EDIT,
      });
    },
    isCurrentRouteActive(routeId: number) {
      return routeId === routesState.activeRouteIndex;
    },
  };

  return (
    <RouteSettingsContext.Provider value={value}>
      {children}
    </RouteSettingsContext.Provider>
  );
}
