import { Container, CssBaseline } from "@mui/material";
import { Box } from "@mui/system";
import { Route, Routes } from "react-router-dom";

import RequireAuth from "./auth/Auth";
import AuthProvider from "./context/AuthContext";
import Header from "./components/Header";
import LiveRoute from "./pages/LiveRoute";
import LoginPage from "./pages/Login";
import RouteSettings from "./pages/RouteSettings";
import { RouteSettingsProvider } from "./context/RouteSettingsContext";
import { Wrapper } from "@googlemaps/react-wrapper";
import DriverView from "./pages/DriverView";

function App() {
  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        paddingTop: "80px",
        minHeight: "100vh",
      }}
    >
      <Container maxWidth={false} component="main">
        <CssBaseline />
        <AuthProvider>
          <Header />
          <RouteSettingsProvider>
            <Wrapper apiKey={"AIzaSyBICEhtmrwbzEksE8uX7ik006b7CPOt5iI"}>
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route
                  path="/settings"
                  element={
                    <RequireAuth>
                      <RouteSettings />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/"
                  element={
                    <RequireAuth>
                      <LiveRoute />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/driver-view"
                  element={
                    <RequireAuth>
                      <DriverView />
                    </RequireAuth>
                  }
                />
              </Routes>
            </Wrapper>
          </RouteSettingsProvider>
        </AuthProvider>
      </Container>
    </Box>
  );
}

export default App;
