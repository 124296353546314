import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { AxiosError } from "axios";
import { FormEvent, useContext, useState } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";
import ErrorToast from "../../components/Shared/ErrorToast";
import { AuthContext } from "../../context/AuthContext";
import { ErrorResponse } from "../../interfaces";

interface CustomizedState {
  from: Location;
}

export function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as CustomizedState;
  const auth = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const closeErrorToast = () => setErrorMessage("");

  const from = state.from?.pathname || "/";

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const username = formData.get("username") as string;
    const password = formData.get("password") as string;

    setLoading(true);
    auth
      .signin({ username, password }, rememberMe)
      .then(() => {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        navigate(from, { replace: true });
      })
      .catch((error: AxiosError) => {
        const response = error.response as ErrorResponse;
        console.log(error);
        switch (error.response?.status) {
          case 401:
            setErrorMessage(response.data.message || "Грешни данни");
            break;
          case 422:
            setErrorMessage(response.data.message || "Липсващи данни");
            break;
          default:
            setErrorMessage(error.message);
            break;
        }
      })
      .finally(() => setLoading(false));
  }

  return (
    <Box
      sx={{
        "@media (min-width: 450px)": {
          marginTop: "190px",
        },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: "547px",
          backgroundColor: "#F0F0F0",
          "@media (min-width: 450px)": {
            padding: "73px",
          },
          padding: "20px",
          borderRadius: "4px",
        }}
      >
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Потребителско име"
            name="username"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Парола"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={
              <Checkbox
                value={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                color="primary"
              />
            }
            label="Запомни ме"
          />
          <Button
            type="submit"
            fullWidth
            disabled={loading}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Вход
          </Button>
        </Box>
      </Box>
      <ErrorToast
        errorMessage={errorMessage}
        closeErrorToast={closeErrorToast}
      />
    </Box>
  );
}
