import { OutlinedInput, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useState } from "react";
import { RouteSettingsContext } from "../../../context/RouteSettingsContext";

export default function RouteNameList() {
  const {
    isEdit,
    routes,
    currentRouteCache,
    isCurrentRouteActive,
    editRouteTitle,
    setActive,
  } = useContext(RouteSettingsContext);
  const [titleError, setTitleError] = useState<boolean>(false);

  function handleTitleChange(e: React.ChangeEvent<HTMLInputElement>) {
    editRouteTitle(e.target.value);
    if (e.target.value === "") {
      if (!titleError) {
        setTitleError(true);
      }
    } else {
      if (titleError) {
        setTitleError(false);
      }
    }
  }

  return (
    <>
      {routes.map((route) =>
        isEdit && isCurrentRouteActive(route.id) ? (
          <Box
            sx={{
              backgroundColor: "#e0e0e0",
              padding: "7px",
            }}
            key={route.id}
          >
            <OutlinedInput
              fullWidth
              size="small"
              placeholder="Име"
              defaultValue={currentRouteCache.title}
              onChange={handleTitleChange}
              error={titleError}
              sx={{
                height: "42px",
                backgroundColor: "#fff",
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              height: "46px",
              display: "flex",
              alignItems: "center",
              backgroundColor: isCurrentRouteActive(route.id)
                ? "#bdbdbd"
                : "#fff",
              paddingX: "14px",
            }}
            key={route.id}
            onClick={() => setActive(route)}
          >
            <Typography sx={{ fontSize: "20px", color: "#424242" }}>
              {route.title}
            </Typography>
          </Box>
        )
      )}
    </>
  );
}
