import { CheckCircle, Circle } from "@mui/icons-material";
import { Stack, OutlinedInput, Typography } from "@mui/material";
import { ActiveRouteStop } from "../../../interfaces";

export default function StopRow({ stop }: { stop: ActiveRouteStop }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={3}
      sx={{
        paddingLeft: "13px",
        paddingY: "6px",
        paddingRight: "20px",
        backgroundColor: "#e0e0e0",
        borderRadius: "4px",
      }}
    >
      {stop.status.passed ? (
        <CheckCircle fontSize="small" />
      ) : (
        <Circle fontSize="small" />
      )}
      <OutlinedInput size="small" fullWidth value={stop.title} />
      <Typography textAlign="right" sx={{ width: "90px" }}>
        {!stop.status.passed ? stop.status.eta : null}
      </Typography>
    </Stack>
  );
}
