import Map from "../../components/Map";
import { apiGetActiveRouteMovements, apiGetRoutes } from "../../api";
import { RouteSettingsContext } from "../../context/RouteSettingsContext";
import { useContext, useEffect, useState } from "react";
import { RouteWithTransport } from "../../interfaces";
import Marker from "../../components/Map/Marker";
import { Grid, Stack } from "@mui/material";
import StopRow from "../../components/RouteView/StopRow";

let timedEvent: ReturnType<typeof setInterval>;

export function LiveRoute() {
  const { setRoutes } = useContext(RouteSettingsContext);
  const [activeRoute, setActiveRoute] = useState<RouteWithTransport | null>(
    null
  );

  useEffect(() => {
    apiGetRoutes().then((routes) => {
      setRoutes(routes);
      const activeRoute = routes.find((route) => route.active);
      if (activeRoute) {
        apiGetActiveRouteMovements(activeRoute.id).then((activeRoute) => {
          setActiveRoute(activeRoute);
          if (!timedEvent) {
            timedEvent = setInterval(function () {
              apiGetActiveRouteMovements(activeRoute.id).then(setActiveRoute);
            }, 10000);
          }
        });
      }
    });
    return function () {
      clearInterval(timedEvent);
    };
  }, []);
  const markerBusIcon = {
    path: "M11.2 39.95q-2.45 0-4.175-1.725Q5.3 36.5 5.3 34.05H2V11q0-1.2.9-2.1Q3.8 8 5 8h28.95v8.35h5.25L46 25.4v8.65h-3.55q0 2.45-1.725 4.175Q39 39.95 36.55 39.95q-2.45 0-4.175-1.725Q30.65 36.5 30.65 34.05H17.1q0 2.45-1.725 4.175Q13.65 39.95 11.2 39.95Zm0-3q1.2 0 2.05-.85.85-.85.85-2.05 0-1.2-.85-2.05-.85-.85-2.05-.85-1.2 0-2.05.85-.85.85-.85 2.05 0 1.2.85 2.05.85.85 2.05.85ZM5 31.05h1.1q.85-1.35 2.15-2.15 1.3-.8 2.9-.8 1.6 0 2.925.825 1.325.825 2.175 2.125h14.7V11H5Zm31.55 5.9q1.2 0 2.05-.85.85-.85.85-2.05 0-1.2-.85-2.05-.85-.85-2.05-.85-1.2 0-2.05.85-.85.85-.85 2.05 0 1.2.85 2.05.85.85 2.05.85Zm-2.6-10.2h9.3l-5.55-7.4h-3.75ZM18 21.55Z",
    fillColor: "blue",
    fillOpacity: 0.6,
    strokeWeight: 0,
    rotation: 0,
    scale: 1,
  };

  const sofiaCenterLatLong: google.maps.LatLngLiteral = {
    lat: 42.693896810097684,
    lng: 23.328751426212364,
  };

  const makeWaypoints = (): google.maps.DirectionsWaypoint[] => {
    if (!activeRoute || activeRoute?.stops.length < 3) {
      return [];
    }
    return activeRoute.stops
      .slice(1, activeRoute.stops.length - 1)
      .map((stop) => ({
        location: `${stop.coordinates.lat},${stop.coordinates.lng}`,
      }));
  };
  return (
    <>
      {activeRoute && (
        <Grid container spacing={2}>
          <Grid item xs={6} md={4}>
            <Stack sx={{ marginY: 1 }} spacing={1}>
              {activeRoute &&
                activeRoute.stops.length &&
                activeRoute.stops.map((stop) => (
                  <StopRow key={stop.id} stop={stop} />
                ))}
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <Map
              minHeight="calc(100vh - 100px)"
              firstStop={activeRoute.stops[0].coordinates}
              lastStop={
                activeRoute.stops[activeRoute.stops.length - 1].coordinates
              }
              center={sofiaCenterLatLong}
              zoom={13}
              wayPoints={makeWaypoints()}
            >
              {activeRoute.transportCoordinates && (
                <Marker
                  position={activeRoute.transportCoordinates}
                  icon={markerBusIcon}
                />
              )}
            </Map>
          </Grid>
        </Grid>
      )}
    </>
  );
}
