import { useSortable } from "@dnd-kit/sortable";
import {
  Circle,
  DragIndicator,
  RemoveCircleOutlineOutlined,
} from "@mui/icons-material";
import { IconButton, OutlinedInput, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import { latLngRegEx } from "../../../common";
import { RouteSettingsContext } from "../../../context/RouteSettingsContext";
import { RouteStop } from "../../../interfaces";
import { CSS } from "@dnd-kit/utilities";

export function RouteStopRow({ stop }: { stop: RouteStop }) {
  const { isEdit, editStopCoordinates, editStopTitle, removeStop } =
    useContext(RouteSettingsContext);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: stop.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  function handleLatLngChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (latLngRegEx.test(event.target.value)) {
      if (coordsError) {
        setCoordsError(false);
      }
    } else {
      if (!coordsError) {
        setCoordsError(true);
      }
    }
    const [lat, lng] = event.target.value.split(",");
    editStopCoordinates(
      {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      },
      stop.id
    );
  }

  function handleTitleChange(e: React.ChangeEvent<HTMLInputElement>) {
    editStopTitle(e.target.value, stop.id);
    if (e.target.value === "" && !titleError) {
      if (!titleError) {
        setTitleError(true);
      }
    } else {
      if (titleError) {
        setTitleError(false);
      }
    }
  }

  const [coordsError, setCoordsError] = useState<boolean>(false);
  const [titleError, setTitleError] = useState<boolean>(false);

  const commonStyling = {
    height: "35px",
    backgroundColor: isEdit ? "#fff" : "#eeeeee",
  };
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={0.5}
      key={stop.id}
      sx={{
        ...style,
        paddingLeft: "13px",
        paddingY: "6px",
        paddingRight: "9px",
        backgroundColor: "#e0e0e0",
        borderRadius: "4px",
      }}
      ref={setNodeRef}
    >
      <Box
        sx={{
          color: "#666666",
          marginRight: 1.5,
          display: "flex",
          alignItems: "center",
        }}
      >
        {isEdit ? (
          <IconButton {...listeners} {...attributes}>
            <DragIndicator fontSize="small" />
          </IconButton>
        ) : (
          <Circle fontSize="small" />
        )}
      </Box>
      <OutlinedInput
        placeholder="Заглавие"
        disabled={!isEdit}
        value={stop.title}
        onChange={handleTitleChange}
        error={titleError}
        fullWidth
        size="small"
        sx={commonStyling}
      />
      <OutlinedInput
        placeholder="Координати"
        disabled={!isEdit}
        value={`${stop.coordinates.lat}, ${stop.coordinates.lng}`}
        fullWidth
        error={coordsError}
        onChange={handleLatLngChange}
        size="small"
        sx={commonStyling}
      />
      {isEdit && (
        <IconButton onClick={() => removeStop(stop)}>
          <RemoveCircleOutlineOutlined fontSize="small" />
        </IconButton>
      )}
    </Stack>
  );
}
