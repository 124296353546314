import { Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  apiGetRoutes,
  apiSaveNewRoute,
  apiEditRoute,
  apiDeleteRoute,
} from "../../../api";
import { genericServerErrorHandler } from "../../../common";
import { RouteSettingsContext } from "../../../context/RouteSettingsContext";
import ErrorToast from "../../Shared/ErrorToast";

export default function HeaderCtas() {
  const {
    isEdit,
    currentRouteCache,
    removeActiveRoute,
    duplicateCurrent,
    setRoutes,
    startEdit,
    endEdit,
  } = useContext(RouteSettingsContext);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const closeErrorToast = () => setErrorMessage("");
  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<number>(1);

  useEffect(
    function () {
      apiGetRoutes().then(setRoutes);
    },
    [reload]
  );

  function handleRemoveClick() {
    if (currentRouteCache.isNew) {
      removeActiveRoute();
    } else {
      setLoading(true);
      apiDeleteRoute(currentRouteCache)
        .then(() => setReload(reload + 1))
        .catch((err) => genericServerErrorHandler(err, setErrorMessage))
        .finally(() => setLoading(false));
    }
  }

  function handleSaveClick() {
    setLoading(true);

    const action = currentRouteCache.isNew
      ? apiSaveNewRoute(currentRouteCache)
      : apiEditRoute(currentRouteCache);

    action
      .then(() => setReload(reload + 1))
      .catch((err) => genericServerErrorHandler(err, setErrorMessage))
      .finally(() => setLoading(false));
  }
  return (
    <>
      {isEdit ? (
        <>
          <Button disabled={loading} variant="outlined" onClick={endEdit}>
            Откажи
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            onClick={handleSaveClick}
          >
            Запази
          </Button>
        </>
      ) : (
        <>
          <Button disabled={loading} variant="text" onClick={handleRemoveClick}>
            Изтрий
          </Button>
          {currentRouteCache && !currentRouteCache.isNew && (
            <Button
              disabled={loading}
              onClick={duplicateCurrent}
              variant="outlined"
            >
              Дублирай
            </Button>
          )}
          <Button disabled={loading} variant="contained" onClick={startEdit}>
            Промени
          </Button>
        </>
      )}
      <ErrorToast
        errorMessage={errorMessage}
        closeErrorToast={closeErrorToast}
      />
    </>
  );
}
