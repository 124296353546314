import axios, { AxiosResponse } from "axios";
import { User, UserCredentials } from "../auth/fakeAuth";
import {
  GeoMovement,
  Route,
  RouteWithTransport,
  StopStatus,
} from "../interfaces";

export interface LoginResponse {
  user: User;
  token: string;
}

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: "application/json",
  },
  responseType: "json",
});

instance.interceptors.response.use(function (response: AxiosResponse) {
  return response.data;
});

export function apiSaveNewRoute(route: Route) {
  return instance.post("/routes", route);
}

export function apiEditRoute(route: Route) {
  return instance.put(`/routes/${route.id}`, route);
}

export function apiSetActiveRoute(routeId: number) {
  return instance.put(`/routes/${routeId}/set-active`);
}

export function apiReportMovementOnRoute(
  routeId: number,
  movement: GeoMovement,
  statuses: StopStatus[]
): Promise<RouteWithTransport> {
  return instance.post(`/routes/${routeId}/report-movement`, {
    movement,
    statuses,
  });
}

export function apiStopMovementOnRoute(routeId: number) {
  return instance.put(`/routes/${routeId}/stop-movement`);
}

export function apiDeleteRoute(route: Route) {
  return instance.delete(`/routes/${route.id}`);
}

export function apiGetRoutes(): Promise<Route[]> {
  return instance.get("/routes");
}

export function apiGetActiveRouteMovements(
  routeId: number
): Promise<RouteWithTransport> {
  return instance.get(`/routes/${routeId}/active-movement`);
}

export function login(
  userCredentials: UserCredentials
): Promise<LoginResponse> {
  return instance.post("/login", userCredentials);
}

export function logout() {
  return instance.post("/logout");
}
