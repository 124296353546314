import { Button, Stack, Typography } from "@mui/material";

export default function SettingHeader({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        backgroundColor: "#fff",
        paddingLeft: "14px",
        paddingRight: "9px",
        border: "1px solid #e0e0e0",
        height: "48px",
        borderRadius: "4px 4px 0 0",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          textTransform: "uppercase",
          color: "#666666",
        }}
      >
        {title}
      </Typography>
      {children}
    </Stack>
  );
}
