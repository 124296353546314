import { createContext, useState } from "react";
import { instance } from "../api";
import { fakeAuthProvider, User, UserCredentials } from "../auth/fakeAuth";

interface AuthContextType {
  user: User | null;
  signin: (
    userCredentials: UserCredentials,
    rememberMe: boolean
  ) => Promise<void>;
  signout: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  let initUser = null;
  const localStorageUser = localStorage.getItem("user");
  const localStorageToken = localStorage.getItem("accessToken");
  if (localStorageUser !== null && localStorageToken !== null) {
    initUser = JSON.parse(localStorageUser) as User;
    instance.defaults.headers.common["Authorization"] =
      "Bearer " + localStorageToken;
  }
  const [user, setUser] = useState<User | null>(initUser);

  const signin = (userCredentials: UserCredentials, rememberMe: boolean) => {
    return fakeAuthProvider.signin(userCredentials).then(({ user, token }) => {
      if (rememberMe) {
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("accessToken", token);
      }
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      setUser(user);
    });
  };

  const signout = () => {
    return fakeAuthProvider.signout().then(() => {
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      setUser(null);
    });
  };

  const value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
