/**
 * This represents some generic auth provider API, like Firebase.
 */

import { login, logout } from "../api";

export interface User {
  username: string;
  role: "admin" | "driver" | "owner";
}

export interface UserCredentials {
  username: string;
  password: string;
}

const fakeAuthProvider = {
  signin(userCredentials: UserCredentials) {
    return login(userCredentials);
  },
  signout() {
    return logout();
  },
};

export { fakeAuthProvider };
