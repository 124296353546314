import { Box } from "@mui/system";
import {
  Children,
  cloneElement,
  isValidElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import "./Map.css";

function MapComponent({
  center,
  zoom,
  children,
  firstStop,
  lastStop,
  minHeight,
  wayPoints,
}: {
  center: google.maps.LatLngLiteral;
  zoom: number;
  children: ReactNode | ReactNode[];
  firstStop: google.maps.LatLngLiteral;
  lastStop: google.maps.LatLngLiteral;
  minHeight: string;
  wayPoints?: google.maps.DirectionsWaypoint[];
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [directionsService, setDirectionsService] =
    useState<google.maps.DirectionsService>();
  const [directionsRenderer, setDirectionsRenderer] =
    useState<google.maps.DirectionsRenderer>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center,
          zoom,
        })
      );
    }
  }, [ref, map]);

  useEffect(() => {
    setDirectionsService(new google.maps.DirectionsService());
    setDirectionsRenderer(new google.maps.DirectionsRenderer());

    directionsRenderer?.setMap(map);
    directionsService
      ?.route({
        origin: `${firstStop.lat}, ${firstStop.lng}`,
        destination: `${lastStop.lat}, ${lastStop.lng}`,
        travelMode: google.maps.TravelMode.DRIVING,
        waypoints: wayPoints,
      })
      .then(
        (response) => {
          console.log(response);
          directionsRenderer?.setDirections(response);
        },
        (rejected) => {
          console.log("ERROR");
          console.log(rejected);
        }
      );
  }, [map]);

  return (
    <Box sx={{ minHeight }} ref={ref} id="map">
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          // set the map prop on the child component
          return cloneElement(child, { map });
        }
      })}
    </Box>
  );
}

export default MapComponent;
