import { Logout } from "@mui/icons-material";
import { Divider, IconButton, Stack, Tab, Tabs } from "@mui/material";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

export default function HeaderTabs() {
  const { pathname } = useLocation();
  const { user, signout } = useContext(AuthContext);
  if (!user) {
    return null;
  }

  return (
    <Stack
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      spacing={2}
    >
      <Tabs value={pathname}>
        <Tab label="Map" value="/" to="/" component={Link} />
        {user.role === "admin" && (
          <Tab
            label="Driver view"
            value="/driver-view"
            to="/driver-view"
            component={Link}
          />
        )}
        {user.role === "admin" && (
          <Tab
            label="Settings"
            value="/settings"
            to="/settings"
            component={Link}
          />
        )}
      </Tabs>
      <IconButton onClick={signout} aria-label="Logout">
        <Logout />
      </IconButton>
    </Stack>
  );
}
