import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import Map from "../../components/Map";
import HeaderCtas from "../../components/Settings/HeaderCtas";
import RouteNameList from "../../components/Settings/RouteNameList";
import RouteStops from "../../components/Settings/RouteStops";
import SettingHeader from "../../components/Settings/SettingHeader";
import { AuthContext } from "../../context/AuthContext";
import { RouteSettingsContext } from "../../context/RouteSettingsContext";

export function RouteSettings() {
  const { addRoute, addStop, currentRouteCache, isEdit, routes } =
    useContext(RouteSettingsContext);

  const [showMap, setShowMap] = useState<boolean>(false);

  const { user } = useContext(AuthContext);

  if (!user || user.role !== "admin") {
    return <Navigate to="/" replace />;
  }

  function handleAddRouteClick() {
    if (!routes.length || !routes[routes.length - 1].isNew) {
      addRoute();
    }
  }

  return (
    <Grid container rowSpacing={1.5} columnSpacing={{ xs: 1, sm: 3, md: 5 }}>
      <Grid item xs={6} md={4}>
        <SettingHeader title="Маршрути">
          <Button variant="outlined" onClick={handleAddRouteClick}>
            Създай нов
          </Button>
        </SettingHeader>
      </Grid>
      <Grid item xs={6} md={8}>
        <SettingHeader title="Спирки">
          <Stack direction="row" spacing={1.5}>
            <HeaderCtas />
          </Stack>
        </SettingHeader>
      </Grid>
      <Grid item xs={6} md={4}>
        <Stack spacing={1}>
          <RouteNameList />
        </Stack>
      </Grid>
      <Grid item xs={6} md={8}>
        {currentRouteCache && currentRouteCache.stops && (
          <>
            {showMap ? (
              <Map
                minHeight="calc(100vh - 200px)"
                firstStop={currentRouteCache.stops[0].coordinates}
                lastStop={
                  currentRouteCache.stops[currentRouteCache.stops.length - 1]
                    .coordinates
                }
                center={currentRouteCache.stops[0].coordinates}
                zoom={13}
                wayPoints={currentRouteCache.stops
                  .filter(
                    (_, index) =>
                      index !== 0 &&
                      index !== currentRouteCache.stops.length - 1
                  )
                  .map((stop) => ({
                    location: `${stop.coordinates.lat},${stop.coordinates.lng}`,
                  }))}
              >
                <></>
              </Map>
            ) : (
              <Box
                sx={{
                  backgroundColor: "#fff",
                  padding: "10px",
                  border: "1px solid #e0e0e0",
                }}
              >
                {isEdit && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ marginBottom: 1 }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontStyle: "italic",
                      }}
                    >
                      Дръпни и пусни, за да разместиш последователността на
                      спирките.
                    </Typography>
                    <Button variant="outlined" onClick={addStop}>
                      Добави спирка
                    </Button>
                  </Stack>
                )}
                <RouteStops />
              </Box>
            )}
            <Stack direction="row" justifyContent="end">
              {showMap ? (
                <Button
                  disabled={currentRouteCache.stops.length < 2}
                  onClick={() => setShowMap(false)}
                  variant="text"
                >
                  Виж списък
                </Button>
              ) : (
                <Button
                  variant="text"
                  disabled={currentRouteCache.stops.length < 2}
                  onClick={() => setShowMap(true)}
                >
                  Виж на карта
                </Button>
              )}
            </Stack>
          </>
        )}
      </Grid>
    </Grid>
  );
}
