import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Snackbar } from "@mui/material";

export default function ErrorToast({
  errorMessage,
  closeErrorToast,
}: {
  errorMessage: string;
  closeErrorToast: () => void;
}) {
  return (
    <Snackbar
      open={!!errorMessage.length}
      autoHideDuration={6000}
      message={errorMessage}
      onClose={closeErrorToast}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={closeErrorToast}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
}
