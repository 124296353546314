import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import {
  apiGetRoutes,
  apiReportMovementOnRoute,
  apiSetActiveRoute,
  apiStopMovementOnRoute,
} from "../../api";
import { genericServerErrorHandler } from "../../common";
import StopRow from "../../components/RouteView/StopRow";
import ErrorToast from "../../components/Shared/ErrorToast";
import { AuthContext } from "../../context/AuthContext";
import { RouteSettingsContext } from "../../context/RouteSettingsContext";
import { GeoMovement, RouteWithTransport } from "../../interfaces";

let timedEvent: ReturnType<typeof setInterval>;

const requestWakeLock = async () => {
  try {
  } catch (err) {
    // if wake lock request fails - usually system related, such as battery
    console.log(err);
  }
};

let positionWatchId: number;
let options: PositionOptions;
let currentPosition: GeoMovement = {
  lat: 0,
  lng: 0,
  timeOfTaking: 0,
};

function success(pos: GeolocationPosition) {
  console.log("Geo Check");
  currentPosition.lat = pos.coords.latitude;
  currentPosition.lng = pos.coords.longitude;
  currentPosition.timeOfTaking = pos.timestamp;
}

function error(err: GeolocationPositionError) {
  console.error(`ERROR(${err.code}): ${err.message}`);
}

options = {
  enableHighAccuracy: false,
  timeout: 5000,
  maximumAge: 0,
};

function cleanup() {
  if (timedEvent) {
    clearInterval(timedEvent);
  }
  if (positionWatchId) {
    navigator.geolocation.clearWatch(positionWatchId);
  }
}

export function DriverView() {
  const { routes, setRoutes } = useContext(RouteSettingsContext);
  const { user } = useContext(AuthContext);
  const [activeId, setActiveId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [wakeLock, setWakeLock] = useState<WakeLockSentinel | null>(null);
  const [activeRoute, setActiveRoute] = useState<RouteWithTransport | null>(
    null
  );
  const wakeLockSupported = "wakeLock" in navigator;
  const [enRoute, setEnRoute] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const closeErrorToast = () => setErrorMessage("");

  useEffect(() => {
    if (wakeLockSupported) {
      navigator.wakeLock.request("screen").then((wakeLock) => {
        setWakeLock(wakeLock);
        wakeLock.addEventListener("release", () => {
          // if wake lock is released alter the button accordingly
          console.log("RELEASED!");
        });
      });
    }
    return function () {
      if (wakeLock) {
        wakeLock.release().then(() => {
          setWakeLock(null);
        });
      }
    };
  }, []);

  useEffect(() => {
    apiGetRoutes().then(setRoutes);

    return function () {
      const activeRoute = routes.find((route) => route.active);
      if (activeRoute) {
        apiStopMovementOnRoute(activeRoute.id);
      }
      cleanup();
    };
  }, []);

  if (!user || user.role !== "admin") {
    return <Navigate to="/" replace />;
  }

  function handleChange() {
    if (activeId) {
      setEnRoute(!enRoute);
      if (enRoute) {
        handleStopClick();
      } else {
        handleStartClick();
      }
    }
  }

  function handleStopClick() {
    cleanup();
    if (activeId) {
      setLoading(true);
      apiStopMovementOnRoute(activeId)
        .then(() => {
          setActiveId(null);
          setActiveRoute(null);
        })
        .catch((err) => genericServerErrorHandler(err, setErrorMessage))
        .finally(() => setLoading(false));
    }
  }

  function handleStartClick() {
    if (activeId !== null) {
      setLoading(true);
      apiSetActiveRoute(activeId)
        .then(function () {
          cleanup();
          positionWatchId = navigator.geolocation.watchPosition(
            success,
            error,
            options
          );

          const activeRoute = routes.find((route) => route.id === activeId);
          const destinations = activeRoute
            ? activeRoute.stops.map(
                (stop) =>
                  new google.maps.LatLng(
                    stop.coordinates.lat,
                    stop.coordinates.lng
                  )
              )
            : [];
          timedEvent = setInterval(function () {
            if (currentPosition.timeOfTaking !== 0) {
              const distanceService = new google.maps.DistanceMatrixService();

              const distanceMatrixSuccess = (
                resp: google.maps.DistanceMatrixResponse | null,
                status: google.maps.DistanceMatrixStatus
              ) => {
                console.log(resp);
                if (status === google.maps.DistanceMatrixStatus.OK && resp) {
                  const stopStatuses = resp.rows[0].elements.map(
                    ({ duration, distance }, index) => {
                      const passed = distance.value < 100;
                      return {
                        eta: duration.text,
                        stopId: activeRoute ? activeRoute.stops[index].id : 0,
                        passed,
                      };
                    }
                  );
                  apiReportMovementOnRoute(
                    activeId,
                    currentPosition,
                    stopStatuses
                  )
                    .then(setActiveRoute)
                    .catch((err) =>
                      genericServerErrorHandler(err, setErrorMessage)
                    );
                }
              };
              distanceService.getDistanceMatrix(
                {
                  origins: [
                    new google.maps.LatLng(
                      currentPosition.lat,
                      currentPosition.lng
                    ),
                  ],
                  destinations,
                  travelMode: google.maps.TravelMode.DRIVING,
                },
                distanceMatrixSuccess
              );
            }
          }, 10000);
        })
        .catch((err) => genericServerErrorHandler(err, setErrorMessage))
        .finally(() => setLoading(false));
    }
  }
  return (
    <Box
      sx={{
        maxWidth: "500px",
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Активен маршрут</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={activeId || ""}
          disabled={enRoute}
          label="Активен маршрут"
          onChange={(e) =>
            setActiveId(e.target.value ? parseInt("" + e.target.value) : null)
          }
        >
          {routes.map((route) => (
            <MenuItem key={route.id} value={route.id}>
              {route.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Stack sx={{ marginY: 1 }} spacing={1}>
        {activeRoute &&
          activeRoute.stops.length &&
          activeRoute.stops.map((stop) => (
            <StopRow key={stop.id} stop={stop} />
          ))}
      </Stack>
      {wakeLockSupported && (
        <Typography>Екранът ще седи буден дори без ваша намеса</Typography>
      )}
      <FormControlLabel
        control={
          <Switch
            disabled={loading || activeId === null}
            checked={enRoute}
            onChange={handleChange}
          />
        }
        label="Карам"
      />
      <ErrorToast
        errorMessage={errorMessage}
        closeErrorToast={closeErrorToast}
      />
    </Box>
  );
}
